//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    isComponent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getOuterClass () {
      return this.isComponent ? 'mtc-spinner-component' : 'mtc-spinner-page'
    }
  }
}

import { render, staticRenderFns } from "./Carousel.vue?vue&type=template&id=75969084&"
import script from "./Carousel.vue?vue&type=script&lang=js&"
export * from "./Carousel.vue?vue&type=script&lang=js&"
import style0 from "./Carousel.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeCarouselInner: require('/var/www/motec/website/motec/components/home/CarouselInner.vue').default,Spinner: require('/var/www/motec/website/motec/components/Spinner.vue').default})

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  props: {
    carouselSlides: Array
  },
  data () {
    return {
      polling: null,
      isLoadedDesktop: false,
      isLoadedMobile: false
    }
  },
  beforeDestroy () {
    clearInterval(this.polling)
  },
  created () {
    this.pollData()
  },
  methods: {
    pauseCarousel () {
      clearInterval(this.polling)
      this.pollData()
    },
    pollData () {
      this.polling = setInterval(() => {
        for (let counter = 1; counter <= 5; counter++) {
          let myElement = this.$refs['radio' + counter]
          if (myElement && myElement.checked) {
            counter++
            if (counter > 5) {
              counter = 1
            }
            myElement = this.$refs['radio' + counter]
            if (myElement) {
              myElement.checked = true
            }
            break
          }
        }
      }, 6500)
    },
    onImgLoadDesktop () {
      this.isLoadedDesktop = true
    },
    onImgLoadMobile () {
      this.isLoadedMobile = true
    }
  },
  computed: {
    isLoaded () {
      return this.isLoadedDesktop && this.isLoadedMobile
    }
  }
}

